// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require('tilt.js/src/tilt.jquery.js');
var $ = require('jquery');
window.jQuery = $;
window.$ = $;
require("jquery-mask-plugin")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import toastr from 'toastr';
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "600",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

global.toastr = toastr;


$(document).ready(function () {
    // expandir e diminuir menu desktop admin
    $(".titulo-menu").click(function () {
        $(this).parents("ul").children('li').slideToggle();
    });

    // expandir e diminuir menu celular admin
    $(".menu-media").click(function () {
        $('.menu').slideToggle();
        $('.menu').toggleClass('ativo');
    });

    // expandir e diminuir menu celular pesquisa
    $(".fieldset-cabecalho").click(function () {
        $('.form-pesquisa').slideToggle();
        $(this).children('svg').toggleClass('inverte-seta');
    });

    $(".detalhes-legislacao-box").click(function () {
        $('.conteudo-legislacao').slideToggle();
        $(this).children('svg').toggleClass('inverte-seta');
    });
});
